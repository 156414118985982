<template>
  <NavigationComponent
    :shorter-navigation="router.currentRoute.value.name !== 'LandingPage'"
    :show-back-button="
      router.currentRoute.value.name !== 'LandingPage' &&
      router.currentRoute.value.name !== 'OrderSuccessPage' &&
      router.currentRoute.value.name !== 'PaymentSuccessPage' &&
      router.currentRoute.value.name !== 'SuccessPage'
    " />
  <router-view />
  <FooterComponent />
</template>

<script setup lang="ts">
import NavigationComponent from '@/components/NavigationComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import { useRouter } from 'vue-router';
import { generalStore } from './store';
import { onMounted } from 'vue';

const router = useRouter();

const store = generalStore();

onMounted(() => {
  checkWidth();
  window.addEventListener('resize', checkWidth);
});

function checkWidth() {
  if (window.innerWidth <= 1300 && window.innerWidth >= 801) {
    store.isTablet = true;
  } else {
    store.isTablet = false;
  }

  if (window.innerWidth <= 800) {
    store.isMobile = true;
  } else {
    store.isMobile = false;
  }
}
</script>

<style lang="scss">
@import '@/scss/main.scss';
</style>
<!-- 
sredi strekla na navigacija
date swiper
-->
