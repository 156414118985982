import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '@/assets/logo.svg'
import _imports_1 from '@/assets/icons/phoneIcon.svg'
import _imports_2 from '@/assets/icons/mailIcon.svg'
import _imports_3 from '@/assets/icons/starIcon.svg'


const _hoisted_1 = { class: "navigation-component-wrapper" }
const _hoisted_2 = { class: "navigation-component-wrapper-container" }
const _hoisted_3 = { class: "navigation-component-wrapper-box" }
const _hoisted_4 = {
  key: 0,
  class: "navigation-component-info"
}

import router from '@/router';
import { ref, onMounted, onBeforeUnmount } from 'vue';
// import { onBeforeRouteLeave } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'NavigationComponent',
  props: {
    shorterNavigation: { type: Boolean },
    showBackButton: { type: Boolean }
  },
  setup(__props: any) {

const navigationComponent = ref<HTMLElement | null>(null);
const navigationFooterButton = ref<HTMLElement | null>(null);
const navigationButton = ref<HTMLElement | null>(null);

const props = __props;

const handleScroll = () => {
  if (navigationComponent.value) {
    const scrollY = window.scrollY;
    const isBelowBreakingPoint = window.innerWidth < 1100;
    const isMobile = window.innerWidth < 800;
    const initialBottomPosition = 50;

    if (isBelowBreakingPoint && !isMobile) {
      if (!props.shorterNavigation) {
        if (scrollY > initialBottomPosition) {
          navigationComponent.value.classList.add('navigation-component-scrolled');
          if (navigationButton.value) {
            navigationButton.value.style.position = 'absolute';
            navigationButton.value.style.bottom = `${Math.min(
              10,
              scrollY - initialBottomPosition,
              initialBottomPosition
            )}px`;
            navigationButton.value.style.right = '40px';
          }
        } else if (scrollY >= 0) {
          navigationComponent.value.classList.remove('navigation-component-scrolled');
          if (navigationButton.value) {
            navigationButton.value.style.position = 'absolute';
            navigationButton.value.style.bottom = `-${
              initialBottomPosition - Math.min(scrollY, initialBottomPosition)
            }px`;
            navigationButton.value.style.right = '40px';
          }
        }
      } else {
        if (navigationButton.value) {
          navigationButton.value.style.position = 'relative';
          navigationButton.value.style.bottom = 'auto';
        }
        if (scrollY > 1) {
          navigationComponent.value.classList.add('navigation-component-scrolled');
        } else if (scrollY >= 0) {
          navigationComponent.value.classList.remove('navigation-component-scrolled');
        }
      }
    } else {
      if (navigationButton.value) {
        navigationButton.value.style.position = 'relative';
        navigationButton.value.style.bottom = 'auto';
        navigationButton.value.style.right = 'auto';
      }
      const scrollBy = isMobile ? 90 : 0;
      if (scrollY > scrollBy) {
        navigationComponent.value.classList.add('navigation-component-scrolled');
      } else {
        navigationComponent.value.classList.remove('navigation-component-scrolled');
      }
    }
  }

  if (navigationFooterButton.value) {
    const contact = document.getElementById('contact');
    if (contact) {
      const contactRect = contact.getBoundingClientRect();
      if (contactRect.top < window.innerHeight) {
        navigationFooterButton.value.classList.add('navigation-component-footer-hidden');
      } else {
        navigationFooterButton.value.classList.remove('navigation-component-footer-hidden');
      }
    }
  }
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
  window.addEventListener('resize', handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
  window.removeEventListener('resize', handleScroll);
});

function goToContactForm() {
  router.push({ name: 'LandingPage', hash: '#contact' });
}

function handleGoingBack() {
  if (window.history.length > 1) {
    window.history.back();
  } else {
    router.push({ name: 'LandingPage' });
  }
}
// onBeforeRouteLeave((to, from, next) => {
// onBeforeRouteLeave(() => {
// window.removeEventListener('scroll', handleScroll);
// document.getElementsByTagName('body')[0].style = 'scroll-behavior: instant';
// document.querySelector('#app').style = 'scroll-behavior: instant';
// next();
// setTimeout(() => {
//   document.getElementsByTagName('body')[0].style = 'scroll-behavior: smooth';
//   document.getElementsByTagName('html')[0].style = 'scroll-behavior: smooth';
// }, 500)
// });

return (_ctx: any,_cache: any) => {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["navigation-component", {
      'navigation-component-fixed': !_ctx.shorterNavigation,
      'navigation-component-with-back': _ctx.showBackButton,
    }]),
      ref_key: "navigationComponent",
      ref: navigationComponent
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_RouterLink, { to: {
            name: 'LandingPage',
          } }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("img", { src: _imports_0 }, null, -1)
            ])),
            _: 1
          }),
          _createElementVNode("div", _hoisted_3, [
            (!_ctx.shorterNavigation)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _cache[1] || (_cache[1] = _createStaticVNode("<a class=\"navigation-component-items-item\" href=\"tel:017774104\"><img src=\"" + _imports_1 + "\"><p class=\"normal-text-bold\">01 777 41 04</p></a><a class=\"navigation-component-items-item\" href=\"mailto:hello@nordic-dental.si\" target=\"_blank\"><img src=\"" + _imports_2 + "\"><p class=\"normal-text-bold\">hello@nordic-dental.si</p></a>", 2))
                ], 64))
              : _createCommentVNode("", true),
            _createElementVNode("button", {
              onClick: goToContactForm,
              ref_key: "navigationButton",
              ref: navigationButton,
              class: _normalizeClass(["button", {
              'landing-page-button': _ctx.shorterNavigation,
            }])
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("img", {
                src: _imports_3,
                alt: ""
              }, null, -1),
              _createTextVNode(" Naročite se ")
            ]), 2)
          ])
        ]),
        (_ctx.showBackButton)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              onClick: handleGoingBack,
              class: "product-details-back"
            }, _cache[3] || (_cache[3] = [
              _createElementVNode("div", { class: "arrow left-arrow" }, null, -1),
              _createElementVNode("p", { class: "body-text-bold primary-text" }, "Nazaj", -1)
            ])))
          : _createCommentVNode("", true)
      ])
    ], 2),
    (!_ctx.shorterNavigation)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[4] || (_cache[4] = [
          _createStaticVNode("<a class=\"navigation-component-info-item\" href=\"tel:01 777 41 04\"><img src=\"" + _imports_1 + "\"><p class=\"normal-text-bold\">01 777 41 04</p></a><a class=\"navigation-component-info-item\" href=\"mailto:hello@nordic-dental.si\" target=\"_blank\"><img src=\"" + _imports_2 + "\"><p class=\"normal-text-bold\">hello@nordic-dental.si</p></a>", 2)
        ])))
      : _createCommentVNode("", true),
    (!_ctx.shorterNavigation)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "navigation-component-footer",
          ref_key: "navigationFooterButton",
          ref: navigationFooterButton
        }, [
          _createElementVNode("button", {
            onClick: goToContactForm,
            class: _normalizeClass(["button", {
        'landing-page-button': _ctx.shorterNavigation,
      }])
          }, _cache[5] || (_cache[5] = [
            _createElementVNode("img", {
              src: _imports_3,
              alt: ""
            }, null, -1),
            _createTextVNode(" Naročite se ")
          ]), 2)
        ], 512))
      : _createCommentVNode("", true)
  ], 64))
}
}

})