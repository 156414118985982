<template>
  <div
    class="navigation-component"
    ref="navigationComponent"
    :class="{
      'navigation-component-fixed': !shorterNavigation,
      'navigation-component-with-back': showBackButton,
    }">
    <div class="navigation-component-wrapper">
      <div class="navigation-component-wrapper-container">
        <RouterLink
          :to="{
            name: 'LandingPage',
          }"
          ><img src="@/assets/logo.svg"
        /></RouterLink>
        <div class="navigation-component-wrapper-box">
          <template v-if="!shorterNavigation">
            <a class="navigation-component-items-item" href="tel:017774104">
              <img src="@/assets/icons/phoneIcon.svg" />
              <p class="normal-text-bold">01 777 41 04</p>
            </a>
            <a class="navigation-component-items-item" href="mailto:hello@nordic-dental.si" target="_blank">
              <img src="@/assets/icons/mailIcon.svg" />
              <p class="normal-text-bold">hello@nordic-dental.si</p>
            </a>
          </template>
          <button
            @click="goToContactForm"
            ref="navigationButton"
            class="button"
            :class="{
              'landing-page-button': shorterNavigation,
            }">
            <img src="@/assets/icons/starIcon.svg" alt="" />
            Naročite se
          </button>
        </div>
      </div>
      <div @click="handleGoingBack" class="product-details-back" v-if="showBackButton">
        <div class="arrow left-arrow"></div>
        <p class="body-text-bold primary-text">Nazaj</p>
      </div>
    </div>
  </div>
  <div class="navigation-component-info" v-if="!shorterNavigation">
    <a class="navigation-component-info-item" href="tel:01 777 41 04">
      <img src="@/assets/icons/phoneIcon.svg" />
      <p class="normal-text-bold">01 777 41 04</p>
    </a>
    <a class="navigation-component-info-item" href="mailto:hello@nordic-dental.si" target="_blank">
      <img src="@/assets/icons/mailIcon.svg" />
      <p class="normal-text-bold">hello@nordic-dental.si</p>
    </a>
  </div>
  <div class="navigation-component-footer" v-if="!shorterNavigation" ref="navigationFooterButton">
    <button
      @click="goToContactForm"
      class="button"
      :class="{
        'landing-page-button': shorterNavigation,
      }">
      <img src="@/assets/icons/starIcon.svg" alt="" />
      Naročite se
    </button>
  </div>
</template>

<script lang="ts" setup>
import router from '@/router';
import { ref, onMounted, onBeforeUnmount } from 'vue';
// import { onBeforeRouteLeave } from 'vue-router';

const navigationComponent = ref<HTMLElement | null>(null);
const navigationFooterButton = ref<HTMLElement | null>(null);
const navigationButton = ref<HTMLElement | null>(null);

const props = defineProps<{
  shorterNavigation: boolean;
  showBackButton: boolean;
}>();

const handleScroll = () => {
  if (navigationComponent.value) {
    const scrollY = window.scrollY;
    const isBelowBreakingPoint = window.innerWidth < 1100;
    const isMobile = window.innerWidth < 800;
    const initialBottomPosition = 50;

    if (isBelowBreakingPoint && !isMobile) {
      if (!props.shorterNavigation) {
        if (scrollY > initialBottomPosition) {
          navigationComponent.value.classList.add('navigation-component-scrolled');
          if (navigationButton.value) {
            navigationButton.value.style.position = 'absolute';
            navigationButton.value.style.bottom = `${Math.min(
              10,
              scrollY - initialBottomPosition,
              initialBottomPosition
            )}px`;
            navigationButton.value.style.right = '40px';
          }
        } else if (scrollY >= 0) {
          navigationComponent.value.classList.remove('navigation-component-scrolled');
          if (navigationButton.value) {
            navigationButton.value.style.position = 'absolute';
            navigationButton.value.style.bottom = `-${
              initialBottomPosition - Math.min(scrollY, initialBottomPosition)
            }px`;
            navigationButton.value.style.right = '40px';
          }
        }
      } else {
        if (navigationButton.value) {
          navigationButton.value.style.position = 'relative';
          navigationButton.value.style.bottom = 'auto';
        }
        if (scrollY > 1) {
          navigationComponent.value.classList.add('navigation-component-scrolled');
        } else if (scrollY >= 0) {
          navigationComponent.value.classList.remove('navigation-component-scrolled');
        }
      }
    } else {
      if (navigationButton.value) {
        navigationButton.value.style.position = 'relative';
        navigationButton.value.style.bottom = 'auto';
        navigationButton.value.style.right = 'auto';
      }
      const scrollBy = isMobile ? 90 : 0;
      if (scrollY > scrollBy) {
        navigationComponent.value.classList.add('navigation-component-scrolled');
      } else {
        navigationComponent.value.classList.remove('navigation-component-scrolled');
      }
    }
  }

  if (navigationFooterButton.value) {
    const contact = document.getElementById('contact');
    if (contact) {
      const contactRect = contact.getBoundingClientRect();
      if (contactRect.top < window.innerHeight) {
        navigationFooterButton.value.classList.add('navigation-component-footer-hidden');
      } else {
        navigationFooterButton.value.classList.remove('navigation-component-footer-hidden');
      }
    }
  }
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
  window.addEventListener('resize', handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
  window.removeEventListener('resize', handleScroll);
});

function goToContactForm() {
  router.push({ name: 'LandingPage', hash: '#contact' });
}

function handleGoingBack() {
  if (window.history.length > 1) {
    window.history.back();
  } else {
    router.push({ name: 'LandingPage' });
  }
}
// onBeforeRouteLeave((to, from, next) => {
// onBeforeRouteLeave(() => {
// window.removeEventListener('scroll', handleScroll);
// document.getElementsByTagName('body')[0].style = 'scroll-behavior: instant';
// document.querySelector('#app').style = 'scroll-behavior: instant';
// next();
// setTimeout(() => {
//   document.getElementsByTagName('body')[0].style = 'scroll-behavior: smooth';
//   document.getElementsByTagName('html')[0].style = 'scroll-behavior: smooth';
// }, 500)
// });
</script>
