<template>
  <footer>
    <div class="normal-text-bold" @click="openedTerms = 'privacy'">Privacy policy</div>
    <div class="normal-text-bold" @click="openedTerms = 'T&C'">T&C's</div>
    <div class="normal-text">Copyright: Nordic Dental, {{ new Date().getFullYear() }}.</div>
  </footer>

  <div class="modal-background" v-if="openedTerms && legalText[openedTerms]">
    <div class="modal-content full-width" v-click-outside="closeTerms">
      <div class="modal-content-header">
        <p class="list-text-bold">
          {{ legalText[openedTerms].title }}
        </p>
        <div class="modal-close" @click="closeTerms()">
          <div class="x-icon"></div>
        </div>
      </div>
      <div class="modal-content-legal">
        <template v-for="section in legalText[openedTerms].sections" :key="section.title">
          <p class="list-text-bold" v-if="section.title">{{ section.title }}</p>
          <div class="modal-content-legal-text" v-html="section.content"></div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';

const openedTerms = ref<keyof typeof legalText | null>(null);

const legalText = {
  privacy: {
    title: 'Privacy Policy',
    sections: [
      {
        title: '',
        content:
          'Last updated: [Date]<br/>At [Your Company Name], accessible from [Website URL], one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by [Website Name] and how we use it.<br/>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us at [Contact Email].',
      },
      {
        title: '1. Information We Collect',
        content:
          'Unless otherwise stated, [Your Company Name] and/or its licensors own the intellectual property rights for all material on [Website Name]. All intellectual property rights are reserved. You may access this from [Website Name] for your own personal use, subject to restrictions set in these terms and conditions.<br/>You must not:<br/><ul><li>Republish material from [Website Name]</li><li>Sell, rent, or sub-license material from [Website Name]</li><li>Reproduce, duplicate or copy material from [Website Name]</li><li>Redistribute content from [Website Name]</li></ul>',
      },
      {
        title: '2. Information management',
        content:
          'In these terms and conditions, “Your Content” shall mean any audio, video, text, images, or other material you choose to display on this website. By displaying Your Content, you grant [Your Company Name] a non-exclusive, worldwide irrevocable, sublicensable license to use, reproduce, adapt, publish, and distribute it in any and all media.<br/>Your Content must be your own and must not be infringing on any third party’s rights. [Your Company Name] reserves the right to remove any of Your Content from this website at any time without notice.',
      },
    ],
  },
  'T&C': {
    title: 'Terms and Conditions',
    sections: [
      {
        title: '',
        content:
          'Last updated: [Date]<br/> Welcome to [Your Company Name]!<br/> These terms and conditions outline the rules and regulations for the use of [Your Company Name]’s website, located at [Website URL]. <br/>By accessing this website, we assume you accept these terms and conditions. Do not continue to use [Website Name] if you do not agree to all of the terms and conditions stated on this page.',
      },
      {
        title: '1. License',
        content:
          'Unless otherwise stated, [Your Company Name] and/or its licensors own the intellectual property rights for all material on [Website Name]. All intellectual property rights are reserved. You may access this from [Website Name] for your own personal use, subject to restrictions set in these terms and conditions. <br/> You must not:<ul> <li>Republish material from [Website Name]</li><li>Sell, rent, or sub-license material from [Website Name]</li><li>Reproduce, duplicate or copy material from [Website Name]</li><li>Redistribute content from [Website Name]</li></ul>',
      },
      {
        title: '2. User Content',
        content:
          'In these terms and conditions, “Your Content” shall mean any audio, video, text, images, or other material you choose to display on this website. By displaying Your Content, you grant [Your Company Name] a non-exclusive, worldwide irrevocable, sublicensable license to use, reproduce, adapt, publish, and distribute it in any and all media.<br/>Your Content must be your own and must not be infringing on any third party’s rights. [Your Company Name] reserves the right to remove any of Your Content from this website at any time without notice.',
      },
    ],
  },
};

function closeTerms() {
  openedTerms.value = null;
}
</script>
