import { Product } from '@/types';
import axios from 'axios';
import { defineStore } from 'pinia';

type GeneralStoreState = {
  products: Product[];
  isTablet: boolean;
  isMobile: boolean;
  workingHours: Record<string, { start: number; end: number }>;
};

let productsPromise: Promise<Product[]> | null = null;
let workingHoursRequest: Promise<Record<string, { start: number; end: number }>> | null = null;

export const generalStore = defineStore('generalStore', {
  state: (): GeneralStoreState => ({
    products: [],
    isTablet: false,
    isMobile: false,
    workingHours: {},
  }),
  actions: {
    formatPrice(price: number) {
      const toEuros = new Intl.NumberFormat('sl-SI', {
        style: 'currency',
        currency: 'EUR',
      });

      return toEuros.format(price / 100);
    },
    fetchProducts() {
      if (!productsPromise) {
        productsPromise = axios.get<Product[]>('/api/products').then((response) => {
          this.products = response.data;
          return response.data;
        });
      }

      return productsPromise;
    },

    fetchWorkingHours() {
      if (!workingHoursRequest) {
        workingHoursRequest = axios.get('/api/workhours').then((response) => {
          this.workingHours = response.data;
          return response.data;
        });
      }
      return workingHoursRequest;
    },
  },
});
