import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "normal-text" }
const _hoisted_2 = {
  key: 0,
  class: "modal-background"
}
const _hoisted_3 = { class: "modal-content full-width" }
const _hoisted_4 = { class: "modal-content-header" }
const _hoisted_5 = { class: "list-text-bold" }
const _hoisted_6 = { class: "modal-content-legal" }
const _hoisted_7 = {
  key: 0,
  class: "list-text-bold"
}
const _hoisted_8 = ["innerHTML"]

import { ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'FooterComponent',
  setup(__props) {

const openedTerms = ref<keyof typeof legalText | null>(null);

const legalText = {
  privacy: {
    title: 'Privacy Policy',
    sections: [
      {
        title: '',
        content:
          'Last updated: [Date]<br/>At [Your Company Name], accessible from [Website URL], one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by [Website Name] and how we use it.<br/>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us at [Contact Email].',
      },
      {
        title: '1. Information We Collect',
        content:
          'Unless otherwise stated, [Your Company Name] and/or its licensors own the intellectual property rights for all material on [Website Name]. All intellectual property rights are reserved. You may access this from [Website Name] for your own personal use, subject to restrictions set in these terms and conditions.<br/>You must not:<br/><ul><li>Republish material from [Website Name]</li><li>Sell, rent, or sub-license material from [Website Name]</li><li>Reproduce, duplicate or copy material from [Website Name]</li><li>Redistribute content from [Website Name]</li></ul>',
      },
      {
        title: '2. Information management',
        content:
          'In these terms and conditions, “Your Content” shall mean any audio, video, text, images, or other material you choose to display on this website. By displaying Your Content, you grant [Your Company Name] a non-exclusive, worldwide irrevocable, sublicensable license to use, reproduce, adapt, publish, and distribute it in any and all media.<br/>Your Content must be your own and must not be infringing on any third party’s rights. [Your Company Name] reserves the right to remove any of Your Content from this website at any time without notice.',
      },
    ],
  },
  'T&C': {
    title: 'Terms and Conditions',
    sections: [
      {
        title: '',
        content:
          'Last updated: [Date]<br/> Welcome to [Your Company Name]!<br/> These terms and conditions outline the rules and regulations for the use of [Your Company Name]’s website, located at [Website URL]. <br/>By accessing this website, we assume you accept these terms and conditions. Do not continue to use [Website Name] if you do not agree to all of the terms and conditions stated on this page.',
      },
      {
        title: '1. License',
        content:
          'Unless otherwise stated, [Your Company Name] and/or its licensors own the intellectual property rights for all material on [Website Name]. All intellectual property rights are reserved. You may access this from [Website Name] for your own personal use, subject to restrictions set in these terms and conditions. <br/> You must not:<ul> <li>Republish material from [Website Name]</li><li>Sell, rent, or sub-license material from [Website Name]</li><li>Reproduce, duplicate or copy material from [Website Name]</li><li>Redistribute content from [Website Name]</li></ul>',
      },
      {
        title: '2. User Content',
        content:
          'In these terms and conditions, “Your Content” shall mean any audio, video, text, images, or other material you choose to display on this website. By displaying Your Content, you grant [Your Company Name] a non-exclusive, worldwide irrevocable, sublicensable license to use, reproduce, adapt, publish, and distribute it in any and all media.<br/>Your Content must be your own and must not be infringing on any third party’s rights. [Your Company Name] reserves the right to remove any of Your Content from this website at any time without notice.',
      },
    ],
  },
};

function closeTerms() {
  openedTerms.value = null;
}

return (_ctx: any,_cache: any) => {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("footer", null, [
      _createElementVNode("div", {
        class: "normal-text-bold",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (openedTerms.value = 'privacy'))
      }, "Privacy policy"),
      _createElementVNode("div", {
        class: "normal-text-bold",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (openedTerms.value = 'T&C'))
      }, "T&C's"),
      _createElementVNode("div", _hoisted_1, "Copyright: Nordic Dental, " + _toDisplayString(new Date().getFullYear()) + ".", 1)
    ]),
    (openedTerms.value && legalText[openedTerms.value])
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("p", _hoisted_5, _toDisplayString(legalText[openedTerms.value].title), 1),
              _createElementVNode("div", {
                class: "modal-close",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (closeTerms()))
              }, _cache[3] || (_cache[3] = [
                _createElementVNode("div", { class: "x-icon" }, null, -1)
              ]))
            ]),
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(legalText[openedTerms.value].sections, (section) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: section.title
                }, [
                  (section.title)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(section.title), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", {
                    class: "modal-content-legal-text",
                    innerHTML: section.content
                  }, null, 8, _hoisted_8)
                ], 64))
              }), 128))
            ])
          ])), [
            [_directive_click_outside, closeTerms]
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})